


// <div class="loading-icon perpetuum-mobile"></div>
// <div class="loading-icon impetus"></div>
// <div class="loading-icon full-circle"></div>

@-webkit-keyframes spin {
  
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
}

@keyframes spin {
  
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
}

.loading-icon {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 0.25rem solid #ddd;
  border-top-color: #333;
  display: inline-block;
  margin: 0 8px;
  
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  &.s-24 {
  	width: 24px;
  	height: 24px;
  }
  &.s-32 {
  	width: 32px;
  	height: 32px;
  }
}

.perpetuum-mobile {
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.15, 0.45, 0.85);
  animation-timing-function: cubic-bezier(0.55, 0.15, 0.45, 0.85);
}

.impetus {
  -webkit-animation-timing-function: cubic-bezier(0.5, -0.35, 0.5, 1.35);
  animation-timing-function: cubic-bezier(0.5, -0.35, 0.5, 1.35);
}

.full-circle {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.gravity {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0.8, 0.5, 0.2);
  animation-timing-function: cubic-bezier(0.5, 0.8, 0.5, 0.2);
}

.overshot {
  -webkit-animation-timing-function: cubic-bezier(0.65, 1.5, 0.6, 0.95);
  animation-timing-function: cubic-bezier(0.65, 1.5, 0.6, 0.95);
}

.downhill {
  -webkit-animation-timing-function: cubic-bezier(0.3, 0.1, 0.3, 0.85);
  animation-timing-function: cubic-bezier(0.3, 0.1, 0.3, 0.85);
}

.pendulum {
  -webkit-animation-timing-function: cubic-bezier(0.3, 1.65, 0.7, -0.65);
  animation-timing-function: cubic-bezier(0.3, 1.65, 0.7, -0.65);
}

.wtf {
  -webkit-animation-timing-function: cubic-bezier(1, -2.3, 1, 2.5);
  animation-timing-function: cubic-bezier(1, -2.3, 1, 2.5);
}