

.filter-bar {

    &.fixed-nav {
        min-height: 71.5px;
        
        .navbar {
            position: fixed;
            right: 0;
            left: 0;
            z-index: 1030;

            padding-top: .5rem;
            padding-bottom: .5rem;
            background-color:#fff;
            box-shadow: 0 1px 9px rgba(0,0,0,.05);

            .nav-item {
                .btn {
                    &.btn-outline-secondary {
                        // border-color: transparent;
                        // background-color: #fff;
                        border-color: #e7e7e9;
                        box-shadow: none !important;
                    }
                }
            }

            .form-control {
                border-color: #e7e7e9 !important;
                box-shadow: none !important;
            }
        }
    }

    // Mega Menu
    @media all and (min-width: 992px) {
        .navbar .has-megamenu{
            position:static!important;
        }
        .navbar .megamenu{
            left:2.1em; 
            right:2.1em; 
            width:calc(100% - ( 2.1em * 2));  
        }
    }

    .navbar {
        background-color: transparent; 
        // transition: 
        //     background-color .23s ease-in, 
        //     box-shadow .23s ease-in;

        > div {
            position: relative;
        }

        .nav-item {
            min-width: 150px;
            margin: 0 1.1em; 

            .btn {
                letter-spacing: .8px;
                display: block;
                text-align: left;
                
                &.btn-outline-secondary {
                    display: block;
                    width: 100%;
                    border-color: transparent;
                    background-color: #fff;
                    box-shadow: 0 .15rem 1.75rem 0 rgba(33,40,50,.15)!important;

                    transition: 
                        border-color .23s ease-in, 
                        box-shadow .23s ease-in;

                    &:active,
                    &:focus,
                    &:hover {
                        color: #fff;
                        background-color: #6c757d;
                    }
                }
            }

            .form-control {
                border-color: transparent;
                background-color: #fff;
                box-shadow: 0 .15rem 1.75rem 0 rgba(33,40,50,.15);
            }

            .title {
                // color: #bdc2ce;
                // font-size: 11px;
                // font-weight: 600;
                // text-transform: uppercase;
                letter-spacing: .8px;

                color: #5f6368;
                letter-spacing: 1px;
                font-size: 11px;
                font-size: .6875rem;
                font-weight: 500;
                line-height: 24px;
                text-transform: uppercase;
                opacity: .8;
            }

            .dropdown-toggle {
                padding-right: 25px;
                position: relative;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;

                @media (min-width: 992px){
                    max-width: 220px !important;
                }
                
                &::after {
                    // float: right;
                    // top: 8px;
                    // position: relative;
                    
                    position: absolute !important;
                    right: 10px;
                    top: 14px !important;
                }
            }

        }
    }
}