


$screenWidth: 370px;
$screenHeight: 210px;


.section-hero {
    position: relative;
    height: 60%;
    height: 80vh;
    min-height: 580px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.hero-animation{
	position: relative;
	top: 0;
	left: 0;
	width: 934px;
	height: 773px;
	// background-image: url('../../assets/web-hero.svg');
	background-repeat: no-repeat;
	background-position: center;

    transform-origin: 0 0;


    .screens {
        position: absolute;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, .17);
        
        &-inner {
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: #fcfcfc;
        }

        &-weather {
            top: 120px;
            left: 95px;
            width: $screenWidth;
            height: $screenHeight;

            .screens-inner {
                background-image: url('../../assets/hero-weather-bg.svg');
                background-position: 0 0;
                background-size: 100%;
                background-color: #f2f2f2;
            }

            .animation {
                position: absolute;
                top: 5px;
                left: 2px;
                width: 150px;
                height: 150px;
                transform: scale(.6);

                .snowy {
                    left: 20%;
                    top: 50%
                }
            }

            .clock-widget {
                left: auto;
                right: 0;
                top: 5px;
            }

            .arrow {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 300px;
                height: 125px;
                background: url('../../assets/hero-weather-arraow.svg') no-repeat center center transparent;
                background-size: 100%;
                transform: translate3d(-50%, 80%, 0);
                z-index: 1;
            }
        }

        &-burger {
            top: 30px;
            left: 490px;
            width: $screenHeight;
            height: $screenWidth;

            .screens-inner {
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% - 5px);
                background-image: url('../../assets/Layout-2.gif');
                background-position: 0 0;
                background-size: 100%;
                background-color: #fff;
            }
        }

        &-moode {
            top: 430px;
            left: 490px;
            width: $screenWidth;
            height: $screenHeight;

            .screens-inner {
                background-image: url('../../assets/hero-mood-bg.svg'), url('../../assets/hero-mood-bg.png');
                background-position: 0 0;
                background-size: 100%;
                background-color: #fcf5d4;
            }
        }

        &-video {
            top: 360px;
            left: 255px;
            width: $screenHeight;
            height: $screenWidth;

            .screens-inner {
                background-image: url('../../assets/hero-video.gif');
                background-position: 0 0;
                background-size: 100%;
            }

            .arrow {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 300px;
                height: 125px;
                background: url('../../assets/hero-video-arraow.svg') no-repeat center center transparent;
                background-size: 100%;
                transform: translate3d(90%, 29px, 0);
                z-index: 1;
            }
        }
        

    }
}