


$cloudColor: #a7def4;
$snowColor: lighten($cloudColor, 10%);


.snowy {
    animation: snowy 5s ease-in-out infinite 1s;
    background: $cloudColor;
    border-radius: 50%;
    box-shadow: 
        $cloudColor 65px -15px 0 -5px, 
        $cloudColor 25px -25px, 
        $cloudColor 30px 10px, 
        $cloudColor 60px 15px 0 -10px, 
        $cloudColor 85px 5px 0 -5px;
    display: block;
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
}


.snowy:after {
    animation: snowy_shadow 5s ease-in-out infinite 1s;
    background: #000000;
    border-radius: 50%;
    content: '';
    height: 15px;
    width: 120px;
    opacity: 0.2;
    position: absolute;
    left: 8px;
    bottom: -60px;
    transform: scale(1);
}
.snowy:before {
    animation: snowy_snow 2s infinite linear;
    content: '';
    border-radius: 50%;
    display: block;
    height: 7px;
    width: 7px;
    opacity: 0.8;
    transform: scale(.9);
}
@keyframes snowy {
    50% { transform: translateY(-20px); }
}
@keyframes snowy_shadow {
    50% { transform: translateY(20px) scale(.7); opacity: 0.05; }
}
@keyframes snowy_snow {
    0% {  
        box-shadow: 
            rgba(238,238,238,0) 30px 30px, 
            rgba(238,238,238,0) 40px 40px,  
            $snowColor 50px 75px, 
            $snowColor 55px 50px, 
            $snowColor 70px 100px, 
            $snowColor 80px 95px, 
            $snowColor 110px 45px, 
            $snowColor 90px 35px; 
    }
    25% {  
        box-shadow: 
            $snowColor 30px 45px,
            $snowColor 40px 60px,
            $snowColor 50px 90px,
            $snowColor 55px 65px,
            rgba(238,238,238,0) 70px 120px,
            rgba(238,238,238,0) 80px 120px,
            $snowColor 110px 70px,
            $snowColor 90px 60px;
    }
    26% {  
        box-shadow:
            $snowColor 30px 45px,
            $snowColor 40px 60px,
            $snowColor 50px 90px,
            $snowColor 55px 65px,
            rgba(238,238,238,0) 70px 40px,
            rgba(238,238,238,0) 80px 20px,
            $snowColor 110px 70px,
            $snowColor 90px 60px; 
    }
    50% { 
        box-shadow:
            $snowColor 30px 70px,
            $snowColor 40px 80px,
            rgba(238,238,238,0) 50px 100px,
            $snowColor 55px 80px,
            $snowColor 70px 60px,
            $snowColor 80px 45px,
            $snowColor 110px 95px,
            $snowColor 90px 85px;
    }
    51% {
        box-shadow:
            $snowColor 30px 70px,
            $snowColor 40px 80px,
            rgba(238,238,238,0) 50px 45px,
            $snowColor 55px 80px,
            $snowColor 70px 60px,
            $snowColor 80px 45px,
            $snowColor 110px 95px,
            $snowColor 90px 85px;
    }
    75% {
        box-shadow:
            $snowColor 30px 95px,
            $snowColor 40px 100px,
            $snowColor 50px 60px,
            rgba(238,238,238,0) 55px 95px,
            $snowColor 70px 80px,
            $snowColor 80px 70px,
            rgba(238,238,238,0) 110px 120px,
            rgba(238,238,238,0) 90px 110px;
    }
    76% {
        box-shadow:
            $snowColor 30px 95px,
            $snowColor 40px 100px,
            $snowColor 50px 60px,
            rgba(238,238,238,0) 55px 35px,
            $snowColor 70px 80px,
            $snowColor 80px 70px,
            rgba(238,238,238,0) 110px 25px,
            rgba(238,238,238,0) 90px 15px;
    }
    100% {
        box-shadow:
            rgba(238,238,238,0) 30px 120px,
            rgba(238,238,238,0) 40px 120px,
            $snowColor 50px 75px,
            $snowColor 55px 50px,
            $snowColor 70px 100px,
            $snowColor 80px 95px,
            $snowColor 110px 45px,
            $snowColor 90px 35px;
    }
}
