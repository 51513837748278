html,
body {
	
	padding: 0;
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	height: 100%;
}

body {

	// background: rgb(238,244,255);
	// background: linear-gradient(180deg, rgba(238,244,255,1) 0%, rgba(255,255,255,1) 100%);

	background: rgb(238,244,255);
	background: linear-gradient(180deg, rgba(238,244,255,1) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 59%, rgba(238,244,255,1) 100%);
	background-attachment: fixed;
	
	padding-top: 65px;

	a, button {
		&:active,
		&:hover,
		&:focus {
			outline: none;
		}
	}

	.disabled {
		pointer-events: none;
		opacity: 0.89;
	}
}


.loading {
	background: url('./assets/preloader12.gif') no-repeat center transparent;
	position: relative;
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: 0 auto;
}


//Font Weights
.ftw-300 {font-weight: 300;}
.ftw-400 {font-weight: 400;}
.ftw-600 {font-weight: 600;}

//Letterspacing
.ls-1 { letter-spacing: 1px; }
.ls-5 { letter-spacing: .5px; }
.ls-8 { letter-spacing: .8px; }

// Widths
.w-130 {width: 130px !important;}
.w-160 {width: 160px !important;}


.svg-icon {
	width: 64px;
	height: 64px;
	display: block;
	margin: 0 auto;

	svg {
		width: inherit;
		height: inherit;
	}

	&.sm {
		width: 32px;
		height: 32px;
	}

	&.xs {
		width: 24px;
		height: 24px;
	}
}

//https://octicons.github.com/
.octicon {
	display: inline-block;
	vertical-align: text-top;
	fill: currentColor;

	g {
		fill: currentColor;
	}

	&.vatb {vertical-align: text-bottom}
	&.vatm {vertical-align: middle}
}

.use-anim-wrap {
	position: relative;

	svg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}



.section {
	&-header{

		h2 {
			font-weight: 300;
			// text-transform: uppercase;
		}

		small {
		    display: inline-block;
		    font-size: 11px;
		    font-size: .6875rem;
		    font-weight: 600;
		    text-transform: uppercase;
		    letter-spacing: .75px;
		    margin-bottom: .5rem;
		    word-spacing: 2px;
		    color: hsla(0,0%,60%,.6);
		}

		hr {
		    border-color: rgba(117, 117, 117, 0.09);
		    width: 50px;
		    margin-top: 2.5rem;
		    margin-bottom: 1.5rem;
		    font-weight: 300px;
		}

	}

	&-para {
		color: #626881;
		line-height: 2;
    	letter-spacing: .5px;
    	font-weight: 300;
    	font-size: 16px;
	}
}



.input-with-icon {
    position: relative;

    input {
    	padding-left: 44px;
    }

	.icon,
    > svg {
    	position: absolute;
	    transform: translateY(-50%);
	    top: 50%;
	    height: 16px;
	    width: 16px;
	    left: 16px;
		fill: #9e9ea7;
	}
	
	.icon {
		left: 10px;
		
		path {
			stroke: #9e9ea7;
		}
	}
}




@import './scss/component-FancyDropdown.scss';
@import './scss/component-Loading.scss';
@import './scss/custom-WebkitScrolbar.scss';