
#main-nav {
	background-color: #FFF;
	border-color: #1f2532;
    padding: .7rem 1rem;
    box-shadow: 
    	0 1px 2px rgba(43,59,93,.11), 
    	0 0 13px rgba(43,59,93,.09);

	.navbar-nav {
		.nav-link {
			color: #d2d2d2;
			font-weight: 600;
			font-size: 12px;
			letter-spacing: .85px;
			text-transform: uppercase;
			
			&.active,
			&:hover {
				color: #fff;
			}

			&.dropdown-toggle::after {
				display: none;
			}

			&.disabled {
				color: rgba(121, 128, 142, 0.5);
			}
		}

		.btn {
			font-size: 12px;
			letter-spacing: .85px;
			text-transform: uppercase;
		}

		.active {
			.nav-link {
				color: #333;
			}
		}
	}
	
	@media (min-width: 768px) {
		&.navbar-expand-md .navbar-nav .nav-link {
		    padding-right: 1rem;
		    padding-left: 1rem;
		}
	}

	/*@media (max-width: 767.98px){
		#logo {
			margin-right: auto;
		}
	}*/
}


.navbar-brand svg {
    height: 32px;
    // transition: transform .3s ease-in;
    // will-change: transform;

    .word {
        transition: opacity .23s ease-in;
        will-change: opacity;

        path {
            fill: #fff !important;
        }
    }

    .donut {
        transition: transform .3s ease-in;
        will-change: transform;
    }
}