

footer p {
	font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
}

footer {

	.copy {
		font-size: 11px;
	}

	hr {
	    border-color: rgba(255, 255, 255, 0.11);
	    margin: 2em 0;
	}

	.btn {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: .8px;
		color: #ccc;
		display: block;
		text-decoration: none;

		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}

	
	.footer-address{
		color: #ccc;
		font-weight: 400;
		font-size: 11px;
		letter-spacing: .8px;
		
		strong {
			margin-right: 5px;
		} 
	}

	.footer-logo {

		svg {
		    height: 32px;
		    // transition: transform .3s ease-in;
		    // will-change: transform;

		    .word {
		        transition: opacity .23s ease-in;
		        will-change: opacity;

		        path {
		            fill: #fff !important;
		        }
		    }

		    .donut {
		        transition: transform .3s ease-in;
		        will-change: transform;
		    }
		}
	}
}


.social-icons  {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	a {
		color: #fff;
		// background-color: rgba(255, 255, 255, 0.1);
		
		// -webkit-box-shadow: 0 0 21px 0 rgba(0,0,0,.05);
		// box-shadow: 0 0 21px 0 rgba(0,0,0,.05);
		width: 50px;
		height: 50px;
		display: block;
		text-align: center;
		line-height: 50px;
		margin: 0 .3rem;
		border-radius: 50%;
		
		svg {
			stroke: currentColor;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
	
			path {
				stroke: currentColor;
			}
		}
		// -webkit-transition: all .3s ease;
		// transition: all .3s ease;
	
		// &:hover {
		// 	box-shadow: 0 0 10px #000;
		// 	background-color: rgba(255, 255, 255, 1);
		// }
	}
}

