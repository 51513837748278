

.clock-widget {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    z-index: 1;

    .day,
    .time {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        text-align: center;
    }

    .day {
        font-weight: 600;
        font-size: 14px;
    }
    
    .time {
        font-weight: 600;
        font-size: 20px;
    }
}